import React from 'react';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';

import BlogPostBody from '../components/BlogPostBody';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const BlogPost = ({ data }) => {
    const { markdownRemark } = data;
    const { frontmatter, html } = markdownRemark;

    return (
        <Layout {...data.site.siteMetadata}>
            <Seo
                pageTitle={frontmatter.title}
                description={
                    frontmatter.description ??
                    `Read about ${frontmatter.title} on KinkyScat.com`
                }
                {...data.site.siteMetadata}
            />
            <BlogPostBody title={frontmatter.title}>{parse(html)}</BlogPostBody>
        </Layout>
    );
};

export default BlogPost;

export const pageQuery = graphql`
    query ($id: String!) {
        site {
            siteMetadata {
                title
                mainSiteUrl
                social {
                    twitter
                    reddit
                    rss
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
            }
        }
    }
`;
