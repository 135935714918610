import React, { FC } from 'react';

const BlogPostBody: FC<{ title: string }> = ({ title, children }) => (
    <div className="relative overflow-hidden bg-white">
        <div className="mx-auto text-lg max-w-prose">
            <h1>
                <span className="block mt-2 text-3xl font-bold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl">
                    {title}
                </span>
            </h1>
        </div>
        <div className="mx-auto mt-6 prose prose-lg text-gray-500 prose-indigo">
            {children}
        </div>
    </div>
);

export default BlogPostBody;
